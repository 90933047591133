import { useEffect, useState } from "react"

export function Northwind() {
        const [data, setData] = useState(null);
        
        useEffect(()=> {
            if (data == null) {
                fetch(`${process.env.REACT_APP_API_PATH}api/categories`)
                    .then((response) => response.json())
                    .then((jsonData) => setData(jsonData))
                    .catch((reason) => {
                            console.log("Connection exception cought");
                            setData(reason.stack);
                        });
            } 
        }, [data, setData]);

        let containerStyle = {display : "flex"}
        let childStyle = {flexGrow : 1, flexBasis : 0}

        var content;
        if (data == null) {
            content = <h1>"Loading"</h1>;
        } else if (typeof data === "string") {
            content = <h6>{data}</h6>;
        } else {
            content = data.map((element) => {
                return <div key={element.id} style={containerStyle}>
                            <div style={childStyle}>{element.name}</div>
                            <div style={childStyle}>{element.description}</div>
                            <div style={childStyle}>{element.products}</div>
                        </div>
            });
        }
    return(
        <div>
            <div style={containerStyle}>
                <h6 style={childStyle}>Category Name</h6>
                <h6 style={childStyle}>Description</h6>
                <h6 style={childStyle}>Product Count</h6>
            </div>
            <div style={{backgroundColor : "#009090", height : "2px", marginBottom : "5px"}}/>
            {content}        
        </div>
    );
}