import { useState } from "react"

export function Message(props) {
    return <div>
        <h2>{props.msg1}</h2>
        <h3>{props.msg2}</h3>
    </div>
}

export function Counter() {
    const [counter, setCounter] = useState(0);
    
    return <div>
        <h4>{counter}</h4>
        <button onClick={() => {setCounter(counter + 1)}}>Increment</button>
    </div>
}

