import { Message } from "../content";
import { Counter } from "../content";

export function Home() {
    return (
        <div>
            <h1>Hello World!!!</h1>
            <Message msg1="My first message" msg2="My second message"/>
            <Counter/>
        </div>
    );
}