import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {Home} from "./pages/Home.js";
import { GenericPage } from './pages/GenericPage.js';
import { NextPage } from './pages/NextPage.js';
import { Northwind } from './pages/northwind/Northwind.js';
import {LoadTest} from './pages/LoadTest.js';

function App() {
    const URL_HOME = "/";
    const URL_NEXT = "/next";
    const URL_PAGE2 = "/page2";
    const URL_PAGE3 = "/page3";
    const URL_NORTHWIND = "/northwind"
    const URL_LOAD_TEST = "/loadTest"

    let tabsStatus = {
        home : "",
        nextPage : "",
        page2 : "",
        page3 : "",
        northwind : "",
        loadTest : ""
    }
    let cPath = window.location.pathname;
    switch(cPath) {
      case URL_HOME : tabsStatus.home = "active"; 
      break
      case URL_NEXT : tabsStatus.nextPage = "active";
      break
      case URL_PAGE2: tabsStatus.page2 = "active";
      break
      case URL_PAGE3: tabsStatus.page3 = "active";
      break
      case URL_NORTHWIND: tabsStatus.northwind = "active";
      break
      case URL_LOAD_TEST: tabsStatus.loadTest = "active";
      break
      default : break
    }

  return (
    <div style={{marginTop: '5px'}}>
      <nav>
        <div className="nav nav-tabs" style={{display:'flex', justifyContent:'center'}}>
          <a className={`nav-link ${tabsStatus.home}`} type='button' href={URL_HOME}>Home</a>
          <a className={`nav-link ${tabsStatus.nextPage}`} type='button' href={URL_NEXT}>Next page</a>
          <a className={`nav-link ${tabsStatus.page2}`} type='button' href={URL_PAGE2}>Page 2</a>
          <a className={`nav-link ${tabsStatus.page3}`} type='button' href={URL_PAGE3}>Page 3</a>
          <a className={`nav-link ${tabsStatus.northwind}`} type='button' href={URL_NORTHWIND}>Northwind</a>
          <a className={`nav-link ${tabsStatus.loadTest}`} type='button' href={URL_LOAD_TEST}>Load Test</a>
        </div>
      </nav>
      <div style={{padding:"20px"}}>
        <Router>
          <Routes>
            <Route exact path={URL_HOME} Component={Home}/>
            <Route path={URL_NEXT} Component={NextPage}/>
            <Route path={URL_PAGE2} Component={() => {return <GenericPage content="Page 2, Here!"/>}}/>
            <Route path={URL_PAGE3} Component={() => {return <GenericPage content="Who summoned PAGE3 ?!"/>}}/>
            <Route path={URL_NORTHWIND} Component={Northwind}/>
            <Route path={URL_LOAD_TEST} Component={LoadTest}/>
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
