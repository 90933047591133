import { useState } from "react";

export function LoadTest() {
    const[loadResults, setLoadResults] = useState([]);
    const[loadingNo, setLoadingNo] = useState(0);

    let htmlLoading = null;
    if (loadingNo > 0) {
        htmlLoading = <h3>Loadings in progress: {loadingNo}</h3>
    } 

    const htmlLoadResults = loadResults.map((loadResult, index) => {
        return (
            <tr key={index}>
                <td>{loadResult.recievedAt}</td>
                <td>{loadResult.controllerInstanceId}</td>
                <td>{loadResult.startThreadId}</td>
                <td>{loadResult.endThreadId}</td>
                <td>{loadResult.taskId}</td>
                <td>{loadResult.completedIn}</td>
            </tr>
        )
    });

    return(
    <div>
        <h1>Load Test the backend</h1>
        <button onClick={() => {callLoad(setLoadResults, setLoadingNo, loadingNo)}}>Call Load</button>
        {htmlLoading}
        <table style={{width:"100%"}}>
            <thead>
                <tr>
                    <th>Recieved At</th>
                    <th>Controller Instance Id</th>
                    <th>StartThreadId</th>
                    <th>EndThreadId</th>
                    <th>TaskId</th>
                    <th>Completed In</th>
                </tr>
            </thead>
            <tbody>
                {htmlLoadResults}
            </tbody>
        </table>
    </div>);
}

function callLoad(setLoadResults, setLoadingNo, loadingNo) {
    fetch(`${process.env.REACT_APP_API_PATH}api/loadTest/${loadingNo}`)
    .then((response) => {
        return response.json();
    }).then((thisLoadResult)=>{
        setLoadResults((current)=>[...current, thisLoadResult]);
    }).finally(()=>{
        setLoadingNo((current) => current - 1);
    });

    setLoadingNo((current) => current + 1);
}